import 'bootstrap';
import $ from "expose-loader?exposes=$,jQuery!jquery";

import "@fortawesome/fontawesome-free/js/fontawesome";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/regular";
import "@fortawesome/fontawesome-free/js/brands";

import './navbar'
import './header-squares'

import "../scss/main.scss"