import { start } from "@popperjs/core";

document.addEventListener("DOMContentLoaded", function () {
    const nSquares = 5;
    var duration = 10000;
    var header = document.getElementById("header");

    if (header) {
        var s = [];
        for (var i = 0; i < nSquares; i++) {
            var elem = document.createElement("div");
            elem.classList.add("header-square");
            header.appendChild(elem);
            s[i] = elem;
        }

        animate(s[0], 0);
        var i = 1;
        setInterval(function () {
            animate(s[i], i);
            i++;
            i = i % nSquares;
        }, duration / nSquares);

        function animate(element, i) {
            console.log("Animating", i);
            var angleOffset = Math.PI / 4;

            // left top right bottom
            var startSide = getRandomIntInclusive(0, 3);
            var endSide = getRandomIntInclusive(0, 2);
            var startPos = [];
            var endPos = [];

            var sides = [
                [0, null],
                [null, 0],
                [header.clientWidth, null],
                [null, header.clientHeight],
            ];

            var offsets = [
                [-40, null],
                [null, 40],
                [170, null],
                [null, 160],
            ];

            startPos[0] = getRandomIntInclusive(0, header.clientWidth);
            startPos[1] = getRandomIntInclusive(70, header.clientHeight);

            if (sides[startSide][0] != null) startPos[0] = sides[startSide][0];
            if (sides[startSide][1] != null) startPos[1] = sides[startSide][1];

            sides.splice(startSide, 1);

            endPos[0] = getRandomIntInclusive(0, header.clientWidth);
            endPos[1] = getRandomIntInclusive(70, header.clientHeight);

            if (sides[endSide][0] != null) endPos[0] = sides[endSide][0];
            if (sides[endSide][1] != null) endPos[1] = sides[endSide][1];

            if (offsets[startSide][0] != null)
                startPos[0] += offsets[startSide][0];
            if (offsets[startSide][1] != null)
                startPos[1] += offsets[startSide][1];

            offsets.splice(startSide, 1);

            if (offsets[endSide][0] != null) endPos[0] += offsets[endSide][0];
            if (offsets[endSide][1] != null) endPos[1] += offsets[endSide][1];

            var angle = Math.atan2(
                endPos[1] - startPos[1],
                endPos[0] - startPos[0]
            );

            element.animate(
                [
                    {
                        transform:
                            "translate(" +
                            startPos[0] +
                            "px, " +
                            startPos[1] +
                            "px) rotate(" +
                            (angle - angleOffset) +
                            "rad)",
                    },
                    {
                        transform:
                            "translate(" +
                            endPos[0] +
                            "px, " +
                            endPos[1] +
                            "px) rotate(" +
                            (angle - angleOffset) +
                            "rad)",
                    },
                ],
                {
                    duration: duration,
                }
            );
        }
    }

});

function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1) + min); //The maximum is inclusive and the minimum is inclusive
}
